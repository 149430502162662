// ProductCreateSteps.jsx
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Steps,
  Upload,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddressAutocomplete from "./AddressAutocomplete";
import { getCookie } from "./cookieUtils";

const { Step } = Steps;
const { Option } = Select;
const { RangePicker } = DatePicker;

const tagSuggestions = [
  "electronics",
  "furniture",
  "clothing",
  "sports",
  "new",
  "popular",
];
const currencyOptions = ["ZAR", "EUR", "GBP"];

const ProductCreateSteps = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [imageFiles, setImageFiles] = useState([]);
  const [form] = Form.useForm();
  const [payloadData, setPayloadData] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Fetch categories from the backend when the component mounts
    const fetchCategories = async () => {
      try {
        const token = getCookie("supplier-token");
        if (!token) {
          message.error("You must be logged in to fetch categories.");
          return;
        }

        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            "/marketplace/supplier-get-product-categories",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setCategories(response.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        const errorMessage =
          error.response?.data?.message || "Failed to load categories";
        message.error(errorMessage);
      }
    };

    fetchCategories();
  }, []);

  const next = async () => {
    try {
      // Validate only the fields in the current step
      console.log("Current Step:", currentStep); // Debugging
      console.log("Form Data:", formData); // Debugging
      console.log("payloadData Data:", payloadData); // Debugging
      const stepFields = steps[currentStep].fields;
      await form.validateFields(stepFields);
      setCurrentStep(currentStep + 1);
    } catch (error) {
      message.warning("Please fill in the required fields to proceed");
    } finally {
      window.scrollTo(0, 0);
    }
  };

  const prev = () => setCurrentStep(currentStep - 1);

  const handleSubmit = async () => {
    try {
      // Prepare the payload
      const payload = { ...formData };
      console.log("Form Data Before Processing:", payload); // Debugging

      // Handle promo_period if present
      if (payload.promo_period) {
        payload.promo_start = payload.promo_period[0].toISOString();
        payload.promo_end = payload.promo_period[1].toISOString();
        delete payload.promo_period;
      }

      // Handle JSON fields (except dimensions)
      const jsonFields = [
        "bulk_discount",
        "frequently_bought_with",
        "meta",
        "social_links",
        "regulatory_certifications",
      ];

      for (const field of jsonFields) {
        if (payload[field]) {
          try {
            const parsedValue = JSON.stringify(payload[field]);
            payload[field] = JSON.parse(parsedValue);
          } catch {
            message.error(
              `${field.replace(/_/g, " ")} must be a valid JSON string.`
            );
            return;
          }
        }
      }

      // Serialize dimensions as JSON string
      if (payload.dimensions) {
        // Validate that dimensions have height, width, and length
        const { height, width, length } = payload.dimensions;
        if (
          height === undefined ||
          width === undefined ||
          length === undefined
        ) {
          message.error("All dimensions (height, width, length) are required.");
          return;
        }

        // Validate that height, width, and length are numbers
        if (
          typeof height !== "number" ||
          typeof width !== "number" ||
          typeof length !== "number"
        ) {
          message.error("Dimensions must be numbers.");
          return;
        }

        // Serialize dimensions
        payload.dimensions = JSON.stringify(payload.dimensions);
      }

      console.log("Payload After Processing:", payload); // Debugging
      setLoading(true);
      // Submit the product data
      const productResponse = await axios.post(
        process.env.REACT_APP_API_URL + "/marketplace/supplier-addproduct",
        {
          ...payload,
          attributes: {
            device: window.navigator.platform,
            browser: window.navigator.userAgent,
            previousPage: document.referrer,
            localStorage: JSON.stringify(localStorage),
            cookies: document.cookie,
          },
        },
        {
          headers: {
            Authorization: getCookie("supplier-token"),
          },
        }
      );
      const productId = productResponse.data.productId;

      // Handle image uploads if any
      if (imageFiles.length > 0) {
        const imageFormData = new FormData();
        imageFiles.forEach((file) => imageFormData.append("images", file));
        message.info("Uploading images...");
        await axios.post(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimages/${productId}`,
          imageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: getCookie("supplier-token"),
            },
          }
        );
      }

      message.success("Product created successfully");
      form.resetFields();
      setFormData({});
      setImageFiles([]);
      setCurrentStep(0);
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error creating product:", error);
      const errorMessage =
        error.response?.data?.message || "Failed to create product";
      message.error(errorMessage);
      setLoading(false);
    }
  };

  const onFormChange = (changedValues, allValues) => {
    // **Fix: Use allValues to ensure nested fields are preserved**
    setFormData({
      ...formData,
      ...allValues,
    });
    setPayloadData({
      ...payloadData,
      ...changedValues,
    });
  };

  const handleAddressSelect = ({ address, lat, lng }) => {
    setFormData({ ...formData, address, latitude: lat, longitude: lng });
    form.setFieldsValue({ address });
  };

  const handleImageUpload = ({ fileList }) => {
    if (fileList.length > 5) {
      message.error("You can only upload a maximum of 5 images");
      return;
    }
    setImageFiles(fileList.map((file) => file.originFileObj));
  };

  const steps = [
    {
      title: "Basic Information",
      fields: ["name", "description", "sku"],
      content: (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item
              label="Product Name"
              name="name"
              rules={[{ required: true, message: "Product name is required" }]}
            >
              <Input placeholder="Enter product name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input.TextArea
                placeholder="Enter product description"
                rows={3}
              />
            </Form.Item>
            <Form.Item
              label="SKU"
              name="sku"
              rules={[{ required: true, message: "SKU is required" }]}
            >
              <Input placeholder="Enter SKU" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className="step-guide">
              <h3>Basic Information</h3>
              <p>
                Provide essential information about your product, including
                name, description, and SKU.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Pricing & Stock",
      fields: ["price", "sale_price", "currency", "stock", "reorder_point"],
      content: (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item
              label="Price"
              name="price"
              rules={[{ required: true, message: "Price is required" }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter price"
              />
            </Form.Item>
            <Form.Item label="Sale Price" name="sale_price">
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter sale price"
              />
            </Form.Item>
            <Form.Item
              label="Currency"
              name="currency"
              initialValue="ZAR"
              rules={[{ required: true, message: "Currency is required" }]}
            >
              <Select>
                {currencyOptions.map((currency) => (
                  <Option key={currency} value={currency}>
                    {currency}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Stock"
              name="stock"
              rules={[
                { required: true, message: "Stock quantity is required" },
              ]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter stock quantity"
                defaultValue={0}
              />
            </Form.Item>
            <Form.Item label="Reorder Point" name="reorder_point">
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter reorder point"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className="step-guide">
              <h3>Pricing & Stock</h3>
              <p>
                Define the pricing, stock levels, and currency for your product.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Dimensions & Location",
      fields: [
        "weight",
        ["dimensions", "height"],
        ["dimensions", "width"],
        ["dimensions", "length"],
        "address",
        "local_availability",
      ],
      content: (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item
              label="Weight (kg)"
              name="weight"
              rules={[{ required: true, message: "Weight is required" }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Weight"
              />
            </Form.Item>
            <Form.Item
              label="Dimensions (cm)"
              className="dimensions"
              rules={[{ required: true, message: "Dimensions are required" }]}
            >
              <Input.Group compact>
                <Form.Item
                  name={["dimensions", "height"]} // Nested dimensions object
                  initialValue={formData?.dimensions?.height || 0} // Set initial value
                  style={{ display: "inline-block", width: "33%" }}
                  rules={[{ required: true, message: "Height is required" }]}
                >
                  <InputNumber
                    placeholder="Height"
                    min={0}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        dimensions: {
                          ...formData.dimensions,
                          height: value,
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["dimensions", "width"]}
                  initialValue={formData?.dimensions?.width || 0}
                  style={{ display: "inline-block", width: "33%" }}
                  rules={[{ required: true, message: "Width is required" }]}
                >
                  <InputNumber
                    placeholder="Width"
                    min={0}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        dimensions: {
                          ...formData.dimensions,
                          width: value,
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["dimensions", "length"]}
                  initialValue={formData?.dimensions?.length || 0}
                  style={{ display: "inline-block", width: "33%" }}
                  rules={[{ required: true, message: "Length is required" }]}
                >
                  <InputNumber
                    placeholder="Length"
                    min={0}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        dimensions: {
                          ...formData.dimensions,
                          length: value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Address is required" }]}
            >
              {form.address}
              <AddressAutocomplete onSelectAddress={handleAddressSelect} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className="step-guide">
              <h3>Dimensions & Location</h3>
              <p>
                Specify physical characteristics and the location where the
                product is available.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Category ",
      fields: ["category_id", "tags"],
      content: (
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Category"
              name="category_id"
              tooltip="Select the category that best fits the product"
              rules={[{ required: true, message: "Category is required" }]}
            >
              <Select placeholder="Choose a category">
                {categories.map((category) => (
                  <Option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Tags"
              name="tags"
              tooltip="Select or add relevant tags for product searchability"
            >
              <Select
                mode="tags"
                placeholder="Add tags (e.g., electronics, new)"
              >
                {tagSuggestions.map((tag) => (
                  <Option key={tag} value={tag}>
                    {tag}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <div className="step-guide">
              <h3>Category</h3>
              <p>Categorize and tag the product for easy searchability.</p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Media, Discount & Delivery",
      fields: [
        "bulk_discount.quantity",
        "bulk_discount.discount",
        "promo_period",
        "delivery_instructions",
      ],
      content: (
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Bulk Discount">
              <Input.Group compact>
                <Form.Item
                  name={["bulk_discount", "quantity"]}
                  tooltip="Minimum quantity for discount"
                >
                  <InputNumber
                    placeholder="Quantity"
                    min={1}
                    style={{ width: "50%" }}
                  />
                </Form.Item>
                <Form.Item
                  name={["bulk_discount", "discount"]}
                  tooltip="Discount percentage"
                >
                  <InputNumber
                    placeholder="Discount (%)"
                    min={0}
                    max={100}
                    style={{ width: "50%" }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label="Promotion Period" name="promo_period">
              <RangePicker />
            </Form.Item>
            <Form.Item
              label="Delivery Instructions"
              name="delivery_instructions"
            >
              <Input.TextArea
                placeholder="Enter specific delivery instructions"
                rows={2}
              />
            </Form.Item>
            <Form.Item label="Product Images">
              <Upload
                listType="picture"
                beforeUpload={() => false}
                multiple
                onChange={handleImageUpload}
                maxCount={5}
              >
                <Button icon={<UploadOutlined />}>Select Images (Max 5)</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <div className="step-guide">
              <h3>Media, Discount & Delivery</h3>
              <p>
                Add promotional details, bulk discounts, delivery instructions,
                and media.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div
      style={{
        maxWidth: 1300,
        margin: "0 auto",
        padding: "20px",
        backgroundColor: "#fff",
      }}
    >
      <Form
        form={form}
        onValuesChange={onFormChange}
        layout="vertical"
        initialValues={formData}
        disabled={loading}
      >
        <Steps current={currentStep}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div style={{ marginTop: 24 }}>{steps[currentStep].content}</div>
        <div style={{ marginTop: 24, textAlign: "center" }}>
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
          {currentStep > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={prev}>
              Previous
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ProductCreateSteps;
