// src/components/SupplierSignup.js

import { Button, Divider, Form, Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import AddressAutocomplete from "./AddressAutocomplete";
import Logo from "./Logo";
import { Link } from "react-router-dom";

const SupplierSignup = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onSelectAddress = ({ address, lat, lng }) => {
    form.setFieldsValue({ address, lat, lng });
  };
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/suppliers-signup`,
        values
      );
      message.success(response.data.message);
      window.location.href = "/supplier/login";
    } catch (error) {
      message.error(error.response?.data?.message || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Logo />
      <Form
        name="supplier_signup"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="login-form"
      >
        <h2>Supplier /Seller Registration</h2>
        <Form.Item
          label="Business Name"
          name="businessName"
          rules={[
            { required: true, message: "Please enter your business name" },
          ]}
        >
          <Input placeholder="Enter your business name" />
        </Form.Item>
        <Form.Item
          label="Contact Person"
          name="contactPerson"
          rules={[
            { required: true, message: "Please enter the contact person name" },
          ]}
        >
          <Input placeholder="Enter contact person name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            { required: true, message: "Please enter your phone number" },
          ]}
        >
          <Input placeholder="Enter your phone number" />
        </Form.Item>
        <Form.Item
          label="Business Address"
          name="address"
          rules={[{ required: true, message: "Please enter your address" }]}
        >
          <AddressAutocomplete onSelectAddress={onSelectAddress} />
        </Form.Item>
        <Form.Item label="Promocode" name="promocode">
          <Input placeholder="Enter the promocode provided by the driver" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password placeholder="Enter a secure password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Register
          </Button>
        </Form.Item>
        <Divider> or </Divider>
        <Link to="/supplier/login">Login as a supplier</Link>{" "}
        {/* Link to Supplier Login */}
        <Divider> or </Divider>
        <Link to="/buyer/login">Login as a buyer</Link>{" "}
        {/* Link to Buyer Login */}
        <Divider> or </Divider>
        <Link to="/">Home</Link> {/* Link to Home */}
      </Form>
    </>
  );
};

export default SupplierSignup;
