// SupplierDashboard.jsx
import {
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Image,
  List,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "./cookieUtils";

const { Title } = Typography;
const { confirm } = Modal;

const SupplierDashboard = () => {
  const [supplierData, setSupplierData] = useState(null);
  const [products, setProducts] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const token = getCookie("supplier-token");
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/supplier-profile",
          {
            headers: { Authorization: token }, // Added 'Bearer ' prefix
          }
        );
        setSupplierData(response.data);
        setCookie("business_name", response.data?.business_name);
      } catch (error) {
        console.error("Error fetching supplier data", error);
        message.error("Failed to fetch supplier data.");
      }
    };

    const fetchProducts = async () => {
      setLoading(true);
      try {
        const token = getCookie("supplier-token");
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/marketplace/supplier-get-products",
          {
            headers: { Authorization: token },
          }
        );
        setProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching products", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSupplierData();
    fetchProducts();
  }, []);

  const showDrawer = (product) => {
    setSelectedProduct(product);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedProduct(null);
  };

  const handleDelete = (productId) => {
    confirm({
      title: "Are you sure you want to delete this product?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const token = getCookie("supplier-token");
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/marketplace/supplier-delete-product/${productId}`, // Updated endpoint
            { headers: { Authorization: token } }
          );
          setProducts((prevProducts) =>
            prevProducts.filter((p) => p.product_id !== productId)
          );
          message.success("Product deleted successfully");
        } catch (error) {
          console.error("Error deleting product", error);
          message.error("Failed to delete product");
        }
      },
    });
  };

  // Function to set a default image
  const handleSetDefaultImage = async (productId, imageUrl) => {
    try {
      const token = getCookie("supplier-token");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-set-default-image`, // Assume you have this endpoint
        { productId, imageUrl },
        { headers: { Authorization: token } }
      );
      // Update the products state to reflect the default image change
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.product_id === productId
            ? { ...product, defaultImage: imageUrl }
            : product
        )
      );
      // Update the selectedProduct if it's the one being edited
      if (selectedProduct && selectedProduct.product_id === productId) {
        setSelectedProduct({ ...selectedProduct, defaultImage: imageUrl });
      }
      message.success("Default image set successfully");
    } catch (error) {
      console.error("Error setting default image", error);
      message.error("Failed to set default image");
    }
  };

  // Define the columns for the Table
  const columns = [
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      render: (images, record) =>
        images && images.length > 0 ? (
          <Image
            src={(record.default_image || images[0])?.replace(
              ".s3.eu-west-1.amazonaws.com",
              ""
            )}
            alt="Product Image"
            width={50}
            height={50}
            style={{ objectFit: "cover" }}
          />
        ) : (
          <div
            style={{
              width: 50,
              height: 50,
              backgroundColor: "#f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#888",
            }}
          >
            No Image
          </div>
        ),
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div onClick={() => showDrawer(record)}>{text}</div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      render: (price) => `${price}`,
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/supplier/product-edit/${record.product_id}`}>
            <Button type="link">Edit</Button>
          </Link>
          <Button
            type="link"
            danger
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click
              handleDelete(record.product_id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Title level={2}>Supplier Dashboard</Title>
      {supplierData ? (
        <Row gutter={16}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Card
              title={supplierData.business_name}
              style={{ marginBottom: "20px" }}
            >
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Contact Person">
                  {supplierData.contact_person}
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                  {supplierData.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {supplierData.email}
                </Descriptions.Item>
                <Descriptions.Item label="Address">
                  {supplierData.address}
                </Descriptions.Item>
              </Descriptions>
              <Button
                type="primary"
                style={{ marginTop: "20px" }}
                onClick={() => navigate("/supplier/edit-profile")}
              >
                Edit Supplier Profile
              </Button>
            </Card>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Card title="Supplier Stats" style={{ marginBottom: "20px" }}>
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Total Products">
                  {supplierData.total_products}
                </Descriptions.Item>
                <Descriptions.Item label="Total Sales">
                  {supplierData.total_sales}
                </Descriptions.Item>
                <Descriptions.Item label="Total Revenue">
                  {supplierData.total_revenue}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      ) : (
        <Skeleton active />
      )}

      <div>
        <Title level={3}>Your Products</Title>
        <Button
          type="primary"
          style={{ marginBottom: "20px" }}
          onClick={() => navigate("/supplier/product-create")}
        >
          Create New Product
        </Button>{" "}
        or
        {/* bulk upload
        /supplier/product-bulkupload
         */}
        <Button
          type="primary"
          style={{ marginBottom: "20px" }}
          onClick={() => navigate("/supplier/product-bulkupload")} // Updated route
        >
          Bulk Upload Products
        </Button>
        <Table
          columns={columns}
          dataSource={products}
          rowKey={(record, index) => `${record.product_id}-${index}`}
          pagination={{ pageSize: 30 }}
          locale={{
            emptyText: loading ? ( // Show loading spinner if loading
              <Skeleton size="large" />
            ) : (
              // Show "No products" if not loading
              <p>No products</p>
            ),
          }}
        />
      </div>

      {/* Drawer for Product Details */}
      <Drawer
        title={selectedProduct ? selectedProduct.name : "Product Details"}
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible} // Updated prop
        width={640}
      >
        {selectedProduct ? (
          <div>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Description">
                {selectedProduct.description}
              </Descriptions.Item>
              <Descriptions.Item label="SKU">
                {selectedProduct.sku}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                ${selectedProduct.price}
              </Descriptions.Item>
              {selectedProduct.sale_price && (
                <Descriptions.Item label="Sale Price">
                  ${selectedProduct.sale_price}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Currency">
                {selectedProduct.currency}
              </Descriptions.Item>
              <Descriptions.Item label="Stock">
                {selectedProduct.stock}
              </Descriptions.Item>
              {selectedProduct.reorder_point !== undefined && (
                <Descriptions.Item label="Reorder Point">
                  {selectedProduct.reorder_point}
                </Descriptions.Item>
              )}
              {selectedProduct.safety_stock_level !== undefined && (
                <Descriptions.Item label="Safety Stock Level">
                  {selectedProduct.safety_stock_level}
                </Descriptions.Item>
              )}
              {selectedProduct.lead_time !== undefined && (
                <Descriptions.Item label="Lead Time">
                  {selectedProduct.lead_time} days
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Stock Status">
                {selectedProduct.stock_status}
              </Descriptions.Item>
              {selectedProduct.warehouse_id && (
                <Descriptions.Item label="Warehouse ID">
                  {selectedProduct.warehouse_id}
                </Descriptions.Item>
              )}
              {selectedProduct.max_delivery_radius !== undefined && (
                <Descriptions.Item label="Max Delivery Radius">
                  {selectedProduct.max_delivery_radius} km
                </Descriptions.Item>
              )}
              {selectedProduct.handling_time !== undefined && (
                <Descriptions.Item label="Handling Time">
                  {selectedProduct.handling_time} days
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Local Availability">
                {selectedProduct.local_availability ? "Yes" : "No"}
              </Descriptions.Item>
              {selectedProduct.latitude && selectedProduct.longitude && (
                <Descriptions.Item label="Location">
                  Latitude: {selectedProduct.latitude}, Longitude:{" "}
                  {selectedProduct.longitude}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Category ID">
                {selectedProduct.category_id}
              </Descriptions.Item>
              {selectedProduct.tags && Array.isArray(selectedProduct.tags) && (
                <Descriptions.Item label="Tags">
                  {selectedProduct.tags.join(", ")}
                </Descriptions.Item>
              )}
              {selectedProduct.dimensions && (
                <Descriptions.Item label="Dimensions (cm)">
                  {selectedProduct.dimensions.height}cm (H) ×{" "}
                  {selectedProduct.dimensions.width}cm (W) ×{" "}
                  {selectedProduct.dimensions.length}cm (D)
                </Descriptions.Item>
              )}
              {/* {selectedProduct.attributes && (
                <Descriptions.Item label="Attributes">
                  {selectedProduct.attributes}
                </Descriptions.Item>
              )} */}
              {selectedProduct.bulk_discount && (
                <Descriptions.Item label="Bulk Discount">
                  Quantity: {selectedProduct.bulk_discount.quantity}, Discount:{" "}
                  {selectedProduct.bulk_discount.discount}%
                </Descriptions.Item>
              )}
              {selectedProduct.frequently_bought_with &&
                Array.isArray(selectedProduct.frequently_bought_with) && (
                  <Descriptions.Item label="Frequently Bought With">
                    {selectedProduct.frequently_bought_with.join(", ")}
                  </Descriptions.Item>
                )}
              {/* {selectedProduct.meta && (
                <Descriptions.Item label="Meta">
                  {selectedProduct.meta}
                </Descriptions.Item>
              )} */}
              {selectedProduct.social_links && (
                <Descriptions.Item label="Social Links">
                  {selectedProduct.social_links}
                </Descriptions.Item>
              )}
              {selectedProduct.regulatory_certifications && (
                <Descriptions.Item label="Regulatory Certifications">
                  {selectedProduct.regulatory_certifications}
                </Descriptions.Item>
              )}
              {selectedProduct.promo_start && selectedProduct.promo_end && (
                <Descriptions.Item label="Promotion Period">
                  {new Date(selectedProduct.promo_start).toLocaleDateString()} -{" "}
                  {new Date(selectedProduct.promo_end).toLocaleDateString()}
                </Descriptions.Item>
              )}
            </Descriptions>

            {/* Image Gallery with Default Image Selection */}
            <div style={{ marginTop: "20px" }}>
              <Title level={4}>Product Images</Title>
              {selectedProduct.images && selectedProduct.images.length > 0 ? (
                <List
                  grid={{ gutter: 16, column: 3 }}
                  dataSource={selectedProduct.images}
                  renderItem={(imageUrl, index) => (
                    <List.Item key={index}>
                      <Space
                        direction="vertical"
                        align="center"
                        style={{ width: "100%" }}
                      >
                        <Image
                          src={imageUrl?.replace(
                            ".s3.eu-west-1.amazonaws.com",
                            ""
                          )}
                          alt={`Product Image ${index + 1}`}
                          width={100}
                          height={100}
                          style={{ objectFit: "cover" }}
                        />
                        <Button
                          type={
                            selectedProduct.defaultImage === imageUrl
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            handleSetDefaultImage(
                              selectedProduct.product_id,
                              imageUrl
                            )
                          }
                        >
                          {selectedProduct.defaultImage === imageUrl
                            ? "Default Image"
                            : "Set as Default"}
                        </Button>
                      </Space>
                    </List.Item>
                  )}
                />
              ) : (
                <p>No images available for this product.</p>
              )}
            </div>

            {/* Edit Button */}
            <div style={{ textAlign: "right", marginTop: "20px" }}>
              <Link to={`/supplier/product-edit/${selectedProduct.product_id}`}>
                <Button type="primary">Edit Product</Button>
              </Link>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </Drawer>
    </div>
  );
};

export default SupplierDashboard;
