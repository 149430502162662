import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Steps,
  Upload,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddressAutocomplete from "./AddressAutocomplete";
import { getCookie } from "./cookieUtils";

const { Step } = Steps;
const { Option } = Select;
const { RangePicker } = DatePicker;

const tagSuggestions = [
  "electronics",
  "furniture",
  "clothing",
  "sports",
  "new",
  "popular",
];
const currencyOptions = ["ZAR", "EUR", "GBP"];

const ProductEditPage = () => {
  const { productId } = useParams();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [productData, setProductData] = useState({});
  const [imageFiles, setImageFiles] = useState([]);
  const [currentImages, setCurrentImages] = useState([]); // Store existing images
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const token = getCookie("supplier-token");
      if (!token) {
        message.error("You must be logged in.");
        return;
      }

      try {
        // Fetch product data
        const productResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-getproductbyid/${productId}`,
          { headers: { Authorization: token } }
        );
        const product = productResponse.data.product;
        setProductData(product);
        try {
          let images = product.images || [];
          images = images.map((image) => {
            if (image.startsWith("http")) {
              return image;
            }
            return `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimage/${productId}/${image}`;
          });
          setCurrentImages(images); // Set current images
        } catch (error) {}

        form.setFieldsValue({
          ...product,
          price: product.price || 0,
          sale_price: product.sale_price || null,
          currency: product.currency || "ZAR",
          stock: product.stock || 0,
          reorder_point: product.reorder_point || 0,
          weight: product.weight || 0,
          dimensions: {
            height: product.dimensions?.height || 0,
            width: product.dimensions?.width || 0,
            length: product.dimensions?.length || 0,
          },
          promo_period:
            product.promo_start && product.promo_end
              ? [moment(product.promo_start), moment(product.promo_end)]
              : [],
          local_availability: product.local_availability || false,
          tags: product.tags || [],
        });

        // Fetch categories
        const categoriesResponse = await axios.get(
          process.env.REACT_APP_API_URL +
            "/marketplace/supplier-get-product-categories",
          { headers: { Authorization: token } }
        );
        setCategories(categoriesResponse.data.categories);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load product or categories.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [productId, form]);

  const next = async () => {
    try {
      const stepFields = steps[currentStep].fields;
      await form.validateFields(stepFields);
      setCurrentStep(currentStep + 1);
    } catch (error) {
      message.warning("Please fill in the required fields to proceed");
    }
  };

  const prev = () => setCurrentStep(currentStep - 1);

  const handleImageUpload = ({ fileList }) => {
    if (fileList.length + currentImages.length > 5) {
      message.error("You can only upload a maximum of 5 images");
      return;
    }
    setImageFiles(fileList.map((file) => file.originFileObj));
  };

  const deleteImage = async (imageUrl) => {
    try {
      const token = getCookie("supplier-token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimages`,
        {
          headers: { Authorization: token },
          data: { imageUrl, productId },
        }
      );
      setCurrentImages((prevImages) =>
        prevImages.filter((image) => image !== imageUrl)
      );
      message.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
      message.error("Failed to delete image");
    }
  };

  const handleSubmit = async () => {
    try {
      const token = getCookie("supplier-token");
      if (!token) {
        message.error("You must be logged in.");
        return;
      }

      const formData = productData;
      if (formData.promo_period) {
        formData.promo_start = formData.promo_period[0].toISOString();
        formData.promo_end = formData.promo_period[1].toISOString();
        delete formData.promo_period;
      }

      await axios.put(
        `http://localhost:5001/marketplace/supplier-update-product/${productId}`,
        { ...formData },
        { headers: { Authorization: token } }
      );

      if (imageFiles.length > 0) {
        const imageFormData = new FormData();
        imageFiles.forEach((file) => imageFormData.append("images", file));
        await axios.post(
          `${process.env.REACT_APP_API_URL}/marketplace/supplier-productimages/${productId}`,
          imageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );
      }

      message.success("Product updated successfully");
      navigate("/supplier/products");
    } catch (error) {
      console.error("Error updating product:", error);
      message.error("Failed to update product.");
    }
  };

  const steps = [
    {
      title: "Basic Information",
      fields: ["name", "description", "sku"],
      content: (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item
              label="Product Name"
              name="name"
              rules={[{ required: true, message: "Product name is required" }]}
            >
              <Input placeholder="Enter product name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input.TextArea
                placeholder="Enter product description"
                rows={3}
              />
            </Form.Item>
            <Form.Item
              label="SKU"
              name="sku"
              rules={[{ required: true, message: "SKU is required" }]}
            >
              <Input placeholder="Enter SKU" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className="step-guide">
              <h3>Basic Information</h3>
              <p>
                Provide essential information about your product, including
                name, description, and SKU.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Pricing & Stock",
      fields: ["price", "sale_price", "currency", "stock", "reorder_point"],
      content: (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item
              label="Price"
              name="price"
              rules={[{ required: true, message: "Price is required" }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter price"
              />
            </Form.Item>
            <Form.Item label="Sale Price" name="sale_price">
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter sale price"
              />
            </Form.Item>
            <Form.Item
              label="Currency"
              name="currency"
              initialValue="ZAR"
              rules={[{ required: true, message: "Currency is required" }]}
            >
              <Select>
                {currencyOptions.map((currency) => (
                  <Option key={currency} value={currency}>
                    {currency}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Stock"
              name="stock"
              rules={[
                { required: true, message: "Stock quantity is required" },
              ]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter stock quantity"
              />
            </Form.Item>
            <Form.Item label="Reorder Point" name="reorder_point">
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder="Enter reorder point"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className="step-guide">
              <h3>Pricing & Stock</h3>
              <p>
                Define the pricing, stock levels, and currency for your product.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Dimensions & Location",
      fields: [
        "weight",
        ["dimensions", "height"],
        ["dimensions", "width"],
        ["dimensions", "length"],
        "address",
        "local_availability",
      ],
      content: (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item
              label="Weight (kg)"
              name="weight"
              rules={[{ required: true, message: "Weight is required" }]}
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                placeholder="Weight"
              />
            </Form.Item>
            <Form.Item
              label="Dimensions (cm)"
              className="dimensions"
              rules={[{ required: true, message: "Dimensions are required" }]}
            >
              <Input.Group compact>
                <Form.Item
                  name={["dimensions", "height"]}
                  rules={[{ required: true, message: "Height is required" }]}
                  style={{ display: "inline-block", width: "33%" }}
                >
                  H
                  <InputNumber
                    placeholder="Height"
                    min={1}
                    style={{ width: "100%" }}
                    value={productData.dimensions?.height}
                    onChange={(value) => {
                      setProductData({
                        ...productData,
                        dimensions: {
                          ...productData.dimensions,
                          height: value,
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["dimensions", "width"]}
                  rules={[{ required: true, message: "Width is required" }]}
                  style={{ display: "inline-block", width: "33%" }}
                >
                  W
                  <InputNumber
                    placeholder="Width"
                    min={1}
                    style={{ width: "100%" }}
                    value={productData.dimensions?.width}
                    onChange={(value) => {
                      setProductData({
                        ...productData,
                        dimensions: {
                          ...productData.dimensions,
                          width: value,
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["dimensions", "length"]}
                  rules={[{ required: true, message: "Length is required" }]}
                  style={{ display: "inline-block", width: "33%" }}
                >
                  L
                  <InputNumber
                    placeholder="Length"
                    min={1}
                    style={{ width: "100%" }}
                    value={productData.dimensions?.length}
                    onChange={(value) => {
                      setProductData({
                        ...productData,
                        dimensions: {
                          ...productData.dimensions,
                          length: value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item
              label={
                productData.address ? productData.address + " (Current)" : ""
              }
              name="address"
              rules={[{ required: true, message: "Address is required" }]}
            >
              <AddressAutocomplete
                placeholder="Enter product location"
                onSelectAddress={(value) => {
                  setProductData({ ...productData, ...value });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className="step-guide">
              <h3>Dimensions & Location</h3>
              <p>
                Specify physical characteristics and the location where the
                product is available.
              </p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Category",
      fields: ["category_id", "tags"],
      content: (
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Category"
              name="category_id"
              rules={[{ required: true, message: "Category is required" }]}
            >
              <Select placeholder="Choose a category">
                {categories.map((category) => (
                  <Option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Tags" name="tags">
              <Select
                mode="tags"
                placeholder="Add tags (e.g., electronics, new)"
              >
                {tagSuggestions.map((tag) => (
                  <Option key={tag} value={tag}>
                    {tag}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <div className="step-guide">
              <h3>Category</h3>
              <p>Categorize and tag the product for easy searchability.</p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Media, Discount & Delivery",
      fields: [
        "bulk_discount.quantity",
        "bulk_discount.discount",
        "promo_period",
        "delivery_instructions",
      ],
      content: (
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Bulk Discount">
              <Input.Group compact>
                <Form.Item name={["bulk_discount", "quantity"]}>
                  <InputNumber
                    placeholder="Quantity"
                    min={1}
                    style={{ width: "50%" }}
                  />
                </Form.Item>
                <Form.Item name={["bulk_discount", "discount"]}>
                  <InputNumber
                    placeholder="Discount (%)"
                    min={0}
                    max={100}
                    style={{ width: "50%" }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label="Promotion Period" name="promo_period">
              <RangePicker />
            </Form.Item>
            <Form.Item
              label="Delivery Instructions"
              name="delivery_instructions"
            >
              <Input.TextArea
                placeholder="Enter specific delivery instructions"
                rows={2}
              />
            </Form.Item>
            <Form.Item label="Current Images">
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {currentImages.map((imageUrl) => (
                  <div key={imageUrl} style={{ position: "relative" }}>
                    <img
                      src={imageUrl?.replace(".s3.eu-west-1.amazonaws.com", "")}
                      alt="Product"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                    <Button
                      icon={<DeleteOutlined />}
                      size="small"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        background: "red",
                        color: "white",
                      }}
                      onClick={() => deleteImage(imageUrl)}
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
            <Form.Item label="Upload New Images">
              <Upload
                listType="picture"
                beforeUpload={() => false}
                multiple
                onChange={handleImageUpload}
                maxCount={5 - currentImages.length}
              >
                <Button icon={<UploadOutlined />}>
                  Select Images (Max {5 - currentImages.length})
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div
      style={{
        maxWidth: 1300,
        margin: "0 auto",
        padding: "20px",
        backgroundColor: "#fff",
      }}
    >
      <div level={5}>
        Edit Product
        <Button
          type="secondary"
          onClick={() => navigate("/supplier/products")}
          className="floatright"
        >
          {" "}
          Back to Products
        </Button>
      </div>
      <h3>
        {
          productData.name // Display product name in the header
        }
      </h3>
      <Form
        onValuesChange={(_, allValues) => {
          // This will update the productData with the new dimensions object
          if (allValues.dimensions) {
            // Update the dimensions object individually if any field changes
            setProductData({
              ...productData,
              dimensions: {
                ...productData.dimensions,
                ...allValues.dimensions, // Update individual dimensions (height, width, length)
              },
            });
          } else {
            setProductData({
              ...productData,
              ...allValues,
            });
          }
        }}
        initialValues={productData}
        disabled={loading}
        form={form}
        layout="vertical"
      >
        <Steps current={currentStep}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div style={{ marginTop: 24 }}>{steps[currentStep].content}</div>
        <div style={{ marginTop: 24, textAlign: "center" }}>
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}

          {currentStep > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={prev}>
              Previous
            </Button>
          )}
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            className="orangebg marginleft40"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ProductEditPage;
