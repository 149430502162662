import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const AnimatedComponent = () => {
  return (
    <div className="logocontainer">
      <div className="logohouse">
        <div className="logoimg" />
      </div>
    </div>
  );
};

export default AnimatedComponent;
