import {
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Modal,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CartButton from "./CartButton";
import ProductListingCard from "./ProductListingCard";
const { Title, Text } = Typography;

const ProductPage = () => {
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselRef = useRef(null); // Ref for the Carousel

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("productId");

  useEffect(() => {
    const fetchProduct = async () => {
      if (productId) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/marketplace/view-product`,
            { productId }
          );
          setProduct(response.data);
          fetchRelatedProducts(response.data.category_id); // Fetch related products based on the category
        } catch (error) {
          message.error("Failed to fetch product details");
        } finally {
          setLoading(false);
        }
      } else {
        message.error("No product ID provided");
        setLoading(false);
      }
    };

    const fetchRelatedProducts = async (categoryId) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/search-products`,
          {
            params: { categoryId, limit: 4 }, // Limit related products
          }
        );
        setRelatedProducts(response.data.products);
      } catch (error) {
        console.error("Failed to fetch related products:", error);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setCurrentImageIndex(0); // Reset the index when closing modal
  };

  const goToPrevious = () => {
    const prevIndex =
      currentImageIndex === 0
        ? product.images.length - 1
        : currentImageIndex - 1;
    setCurrentImageIndex(prevIndex);
    carouselRef.current.goTo(prevIndex); // Programmatically go to previous image
  };

  const goToNext = () => {
    const nextIndex =
      currentImageIndex === product.images.length - 1
        ? 0
        : currentImageIndex + 1;
    setCurrentImageIndex(nextIndex);
    carouselRef.current.goTo(nextIndex); // Programmatically go to next image
  };

  if (loading) {
    return <Spin size="large" style={{ display: "block", margin: "auto" }} />;
  }

  if (!product) {
    return <Text>Product not found</Text>;
  }

  return (
    <div style={{ padding: "0px" }}>
      <Card
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "10px",
          padding: "0px",
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={10} lg={8}>
            {/* Static Main Image */}
            <img
              alt={product.name}
              src={
                product.default_image?.replace(
                  ".s3.eu-west-1.amazonaws.com",
                  ""
                ) ||
                (product.images.length > 0
                  ? product.images[0]?.replace(
                      ".s3.eu-west-1.amazonaws.com",
                      ""
                    )
                  : "https://placehold.co/100")
              }
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => handleImageClick(0)} // Click to open modal for the first image
            />
          </Col>
          <Col xs={24} sm={12}>
            <Title level={4}>{product.name}</Title>
            <Text strong style={{ fontSize: "24px" }}>
              R {product.price} {product.currency}
            </Text>
            <Text
              style={{ display: "block", marginTop: "10px", color: "#999" }}
            >
              {product.stock_status}
            </Text>
            {/* <Text style={{ display: "block", marginTop: "10px" }}>
              Supplier: {product.suppliername || product.supplier_name}
            </Text> */}
            <CartButton product={product} showQuantity={true} />
          </Col>
        </Row>
        <Divider />
        <div style={{ marginTop: "20px" }}>
          <Title level={5}>Product Details</Title>
          <Text>{product.description || "No description available."}</Text>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Title level={5}>Meta Information</Title>
          <Text>SKU: {product.sku || "N/A"}</Text>
          <br />
          <Text>Category: {product.category_name || "N/A"}</Text>
          <br />
          <Text>Weight: {product.weight || "N/A"}</Text>
          <br />
          <Text>
            Dimensions:{" "}
            {(() => {
              if (product.dimensions) {
                const { length, width, height } = product.dimensions;
                return `${length} x ${width} x ${height}`;
              }
            })() || "N/A"}
          </Text>
          <br />
          <Text>Tags: {product.tags || "N/A"}</Text>
        </div>
      </Card>

      {/* Related Products Section */}
      <div style={{ marginTop: "40px" }}>
        <Title level={3}>Related Products</Title>
        <Row gutter={[16, 16]} justify="center">
          {relatedProducts.map((relatedProduct) => (
            <Col xs={24} sm={12} md={8} lg={6} key={relatedProduct.product_id}>
              <ProductListingCard product={relatedProduct} showCartButton />
            </Col>
          ))}
        </Row>
      </div>

      {/* Image Modal */}
      <Modal
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
        centered
      >
        <Carousel
          ref={carouselRef} // Attach the ref here
          dotPosition="bottom"
          afterChange={(current) => setCurrentImageIndex(current)}
          initialSlide={currentImageIndex}
        >
          {product.images.map((image, index) => (
            <div key={index}>
              <img
                alt={product.name}
                src={
                  image?.replace(".s3.eu-west-1.amazonaws.com", "") ||
                  "https://placehold.co/100"
                }
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          ))}
        </Carousel>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {currentImageIndex + 1} / {product.images.length}
        </div>
        <Button
          type="primary"
          onClick={goToPrevious}
          style={{ marginRight: "10px" }}
        >
          Prev
        </Button>
        <Button type="primary" onClick={goToNext}>
          Next
        </Button>
      </Modal>
    </div>
  );
};

export default ProductPage;
