import React, { useState } from "react";
import { Form, Input, Button, message, Divider } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import AddressAutocomplete from "./AddressAutocomplete"; // Import AddressAutocomplete

const BuyerSignup = () => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(""); // State for address
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:5001/marketplace/buyers-signup",
        {
          ...values,
          address,
          lat,
          lng,
        }
      );
      message.success(response.data.message);
      window.location.href = "/buyer/login?signup=success";
    } catch (error) {
      message.error(error.response?.data?.message || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAddress = ({ address, lat, lng }) => {
    setAddress(address);
    setLat(lat);
    setLng(lng);
  };

  return (
    <>
      <Logo />
      <Form
        name="buyer_signup"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="login-form"
      >
        <h1>Buyer Registration</h1>
        <Form.Item
          label="First Name"
          name="firstname"
          rules={[{ required: true, message: "Please enter your first name" }]}
        >
          <Input placeholder="Enter your first name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastname"
          rules={[{ required: true, message: "Please enter your last name" }]}
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            { required: true, message: "Please enter your phone number" },
          ]}
        >
          <Input placeholder="Enter your phone number" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password placeholder="Enter a secure password" />
        </Form.Item>
        {/* Address Autocomplete Section */}
        <Form.Item label="Address" required>
          <AddressAutocomplete
            onSelectAddress={handleSelectAddress}
            placeholder="Enter your address"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Register
          </Button>
        </Form.Item>
        <Divider>Or</Divider>
        <Link
          type="link"
          block
          onClick={() => {
            window.location.href = "/buyer/login";
          }}
        >
          Login as a buyer instead
        </Link>
        <Divider> or </Divider>
        <Link to="/supplier/signup">Create an account as a supplier</Link>{" "}
        <Divider> or </Divider>
        <Link to="/supplier/login">Login as a supplier</Link>{" "}
        {/* Link to Supplier Signup */}
        <Divider> or </Divider>
        <Link to="/">Home</Link> {/* Link to Home */}
      </Form>
    </>
  );
};

export default BuyerSignup;
