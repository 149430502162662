import React from "react";
import { Card } from "antd";

const ProductBulkUpload = () => {
  return (
    <Card style={{ textAlign: "center", marginTop: "20px" }}>
      Bulk uploads
      <h1>Coming Soon</h1>
    </Card>
  );
};

export default ProductBulkUpload;
